@import "src/core/common/assets/styles/mixins";

.content {
  display: block;
  text-align: center;
}

.title {
  @include contentManagerSectionHeading();
}
