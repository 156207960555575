
@import "src/core/common/assets/styles/mixins";

.root {
  color: var(--primary);
  font-size: 12px;
  line-height: 24px;
}

.wrapper {
  display: inline-flex;
  margin-top: 0;
  margin-bottom: 0;
}
