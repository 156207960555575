.imageAvatar {
  border-radius: 50%;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  font-size: 18px;
  color: var(--typography-100);
  background: var(--gradient-eclipse);
}
