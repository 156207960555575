
@import "src/core/common/assets/styles/mixins";

.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 5px;
}

.text {
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 20px;
  overflow-wrap: anywhere;
}

.aboutMore {
  font-size: 14px;
  z-index: 1;
}
