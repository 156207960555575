
@import "src/core/common/assets/styles/mixins";

.block {
  margin-bottom: 30px;

  @include respondTo(l) {
    margin-top: 70px;
    margin-bottom: 0
  }
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  width: 300px;
  padding: 5px 10px;
  align-self: center;
  border-radius: 30px;
  outline: none;
  border: none;
  color: var(--typography-100);
  font-size: 16px;
  line-height: 24px;
  height: 40px;
  background: var(--gradient-pink-base);

  @include transition(all, 150ms, ease-in-out);

  &:active {
    @include scale(1.03)
  }

  @include respondTo(l) {
    width: 380px;
    height: 44px;
  }
}

.buttonIcon {
  display: inherit;
  fill: currentColor !important;
}

.buttonIconBefore {
  margin-left: -6px;
  margin-right: 12px;
}

.buttonIconAfter {
  margin-right: -6px;
  margin-left: 12px;
}

.loadingIndicator {
  color: var(--typography-100) !important;
}

.buttonLabel {
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
}

.buttonSuccess {
  background: var(--success);
  border-radius: 8px;
}

.buttonOutlined {
  background: transparent;
  border: 1px solid var(--primary);
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  background: var(--gradient-pink-base);
}

.textButton {
  padding: 0;
  border: none;
  height: auto;
  width: auto;
  background: transparent;
}

