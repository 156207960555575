@import "src/core/common/assets/styles/mixins";

.content {
  @include pageContainerMaxWidth();
  @include sectionSidePaddings();

  display: flex;
  flex-direction: column;
  align-items: center;

  @include respondTo(l) {
    padding-left: 100px;
    padding-right: 100px;
  }
}

.title {
  text-align: center;
  color: var(--primary-100);
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 24px;

  @include respondTo(l) {
    font-size: 40px;
    line-height: 54px;
    margin-bottom: 48px;
  }
}

.topicListWrapper {
  width: 100%;
}

.topicList {
  max-width: 400px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: -16px;
  margin-right: -10px;

  @include respondTo(l) {
    max-width: none;
    margin-right: -28px;
  }
}

.topicListItem {
  width: 50%;
  max-width: 100%;
  padding-bottom: 16px;
  padding-right: 10px;

  @include respondTo(l) {
    width: 197px;
    padding-right: 28px;
  }
}

.topic {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  background: var(--primary-800);
  box-shadow: 0 4px 6px rgba(0, 0, 4, 0.4);
  border-radius: 6px;
  padding: 12px 10px;
  color: var(--typography-100);

  @include respondTo(l) {
    padding: 20px 12px;
  }
}

.link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.topicImage {
  @include positionFutureImage(100px);

  margin-bottom: 16px;

  @include respondTo(l) {
    margin-bottom: 28px;
  }
}


.topicText {
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;
  margin-top: auto;
  text-align: center;
}
