@import "src/core/common/assets/styles/mixins";

.root {
  background: var(--white-with-opacity-5);
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 80px;
  border-radius: 20px;

  @include respondTo(l) {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.content {
  @include pageContainerMaxWidth();

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 0;

  @include respondTo(l) {
    flex-direction: row;
    padding: 60px 80px;
  }
}

.description {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 446px;
  flex-shrink: 0;

  @include respondTo(l) {
    margin-right: 100px;
    align-items: flex-start;
  }
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: var(--typography-100);
  margin-bottom: 20px;
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;

  @include respondTo(l) {
    padding-left: 34px;
    font-size: 40px;
    line-height: 54px;
    margin-bottom: 24px;
    text-align: left;
  }
}

.featuresList {
  display: flex;
  flex-direction: column;
  margin-bottom: 38px;
  padding-left: 16px;
  padding-right: 16px;

  @include respondTo(l) {
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.featureItem {
  position: relative;
  font-size: 14px;
  line-height: 140%;
  color: var(--typography-100);
  margin-bottom: 12px;
  padding-left: 36px;

  strong {
    font-weight: 600;
  }

  @include respondTo(l) {
    font-size: 16px;
    margin-bottom: 16px;
  }
}

.icon {
  --tertiary-800: #E4C4F0;

  width: 24px;
  height: 24px;
  fill: var(--tertiary-800);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

.imageContainer {
  flex-grow: 1;
  position: relative;
  max-height: 313px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  @include respondTo(l) {
    max-height: 510px;
  }
}

.image {
  @include positionFutureImage();
}

.imageDesktop {
  @include hideOnMobile();
}

.imageMobile {
  @include hideOnDesktop();
}

.buttonWrapper {
  @include respondTo(l) {
    margin-top: 32px;
  }
}

.button {
  background: var(--primary);
  color: var(--typography-900);
  font-weight: 600;
  justify-content: space-between;
  padding-right: 48px;
  padding-left: 48px;
  font-size: 18px;
  max-width: 300px;
}

.buttonLabel {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 16px;
  font-weight: 600;

  @include respondTo(l) {
    font-size: 18px;
  }
}

.buttonIcon {
  width: 18px;
  height: 18px;
  fill: currentColor;
}
