@import "src/core/common/assets/styles/mixins";

.container {
  border-radius: 20px;
}

.infoWrapper {
  color: var(--typography-100);
  display: flex;
  align-items: flex-start;
}

.reviewInfo {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  max-width: 55%;
  flex-basis: 50%;
  overflow: hidden;
}

.rating {
  margin-left: auto;
}

.quoteWrapper {
  color: var(--typography-100);
  flex-grow: 1;
  overflow-y: auto;
}

.name {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  max-height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.quoteContainer {
  display: flex;
  margin-top: 10px;
  text-overflow: ellipsis;
  margin-bottom: 10px;
}

.quotationMarks {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
}

.quoteText, .quoteDarkText {
  width: 100%;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;

  @include clampInlineLines(5);
}

.quoteDarkText {
  line-height: 24px;
}

.quoteDark {
  fill: var(--gradient-pink-base);
}

@include whenThemeIs(light) {
  .infoWrapper, .quoteWrapper {
    color: var(--typography-900);
  }
}
