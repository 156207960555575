@import "src/core/common/assets/styles/mixins";
@import "src/core/common/assets/styles/vars.module";

.root {
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 24px;
  position: relative;

  @include respondTo(l) {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 44px;
  }
}

.content {
  background-color: var(--white-with-opacity-5);
  border-radius: 20px;
  padding: 32px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include pageContainerMaxWidth($contentManagerSectionWidth);

  @include respondTo(l) {
    padding: 50px 80px;
    margin-left: auto;
    margin-right: auto;
  }
}