// breakpoints
$maximalMobileWidth: 1024px;
$minimalDesktopWidth: 1025px;
$maximalContainerWidth: 1366px;
$maximalContentWidth: 1068px;
$contentManagerSectionWidth: 1326px;
$contentManagerSectionTextWidth: 1000px;
$contentManagerSectionMinimalTextWidth: 800px;
$expertCatalogueMaxWidthDesktopListItemLength: 1328px;

// paddings
$sectionSidePadding: 15px;
$sectionDesktopPadding: 50px;

// z-indexes
$stickyHeaderZIndex: 1000;
$fixedSupportButtonZIndex: 1000;
$appLoaderZIndex: 1001;
$invisibilityZIndex: -9999;

// margins
$scrollMarginTopDesktop: 180px;
$scrollMarginTopMobile: 130px;

:export {
  maximalMobileWidth: $maximalMobileWidth;
  maximalContainerWidth: $maximalContainerWidth;
  stickyHeaderZIndex: $stickyHeaderZIndex;
  fixedSupportButtonZIndex: $fixedSupportButtonZIndex;
  appLoaderZIndex: $appLoaderZIndex;
  contentManagerSectionWidth: $contentManagerSectionWidth;
  contentManagerSectionTextWidth: $contentManagerSectionTextWidth;
  contentManagerSectionMinimalTextWidth: $contentManagerSectionMinimalTextWidth;
  invisibilityZIndex: $invisibilityZIndex;
  scrollMarginTopDesktop: $scrollMarginTopDesktop;
  scrollMarginTopMobile: $scrollMarginTopMobile;
}
