@import "src/core/common/assets/styles/vars.module";
@import "src/core/common/assets/styles/mixins";

.root {
  background: var(--secondary-600);
  border-radius: 16px;
  padding-top: 15px;
  position: relative;
}

.overlayButton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: transparent;
}

.content {
  position: relative;
  display: grid;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr repeat(4, auto);
  grid-template-areas:
                "image"
                "name"
                "status"
                "review"
                "experience"
                "action";

  @include respondTo(l) {
    grid-template-columns: 100px 1fr;
    grid-template-rows: repeat(6, min-content) auto;
    grid-template-areas:
                "image name"
                "image review"
                "image status"
                "divider divider"
                "experience experience"
                "description description"
                "action action";
  }
}

.experienceWrapper, .description, .divider {
  margin-right: 12px;
  margin-left: 12px;

  @include respondTo(l) {
    margin-right: 20px;
    margin-left: 20px;
  }
}

.nameWrapper, .reviewWrapper, .status {
  margin-right: 12px;
  margin-left: 12px;

  @include respondTo(l) {
    margin-left: 8px;
    margin-right: 20px;
  }
}

.imageWrapper {
  grid-area: image;
  position: relative;
  max-width: 136px;
  width: 100%;
  height: 122px;
  margin-left: auto;
  margin-right: auto;

  @include respondTo(l) {
    width: 80px;
    height: 80px;
    margin-left: 20px;
  }
}

.image {
  border-radius: 16px 16px 0 0;
  object-fit: cover;

  @include respondTo(l) {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}

.nameWrapper {
  grid-area: name;
  display: flex;
  justify-content: center;

  @include respondTo(l) {
    overflow: hidden;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}

.name {
  color: var(--typography-100);
  font-weight: 600;
  font-size: 18px;
  line-height: 135%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 4px 8px 18px;
  max-width: 136px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: var(--secondary-800);

  @include respondTo(l) {
    text-align: left;
    display: inline-block;
    padding: 0;
    max-width: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: transparent;

    @include truncateText();
  }
}

.status {
  grid-area: status;
  font-size: 12px;
  line-height: 16px;
  background: var(--success-400);
  border-radius: 22px;
  padding: 4px 16px;
  font-weight: 600;
  text-align: center;
  max-width: 80px;
  margin: -10px auto 5px;

  @include respondTo(l) {
    margin: 4px 0 13px 8px;
  }
}

.divider {
  display: none;

  @include respondTo(l) {
    display: block;
    grid-area: divider;
    height: 1px;
    background-color: var(--white-with-opacity-5);
    margin-bottom: 12px;
  }
}

.reviewWrapper {
  grid-area: review;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  flex-wrap: wrap;
}

.rating {
  display: flex;
  color: var(--typography-100);
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;

  @include respondTo(l) {
    font-size: 18px;
  }
}

.star {
  margin-left: 6px;
  margin-right: 6px;
  width: 20px;
  height: 20px;
  fill: var(--star);
}

.reviews {
  color: var(--typography-100);
  font-size: 12px;
  line-height: 130%;

  @include respondTo(l) {
    font-size: 10px;
  }
}

.experienceWrapper {
  grid-area: experience;
  margin-bottom: 18px;
}

.experienceItem {
  display: flex;
  align-items: center;
  margin-top: 6px;
  color: var(--typography-300);
  font-size: 12px;
  line-height: 130%;
  flex-wrap: wrap;
}

.experienceAmount {
  font-weight: 600;
  color: var(--typography-100);
  margin-right: 5px;
}

.icon {
  margin-right: 6px;
  fill: var(--primary-200);
  width: 12px;
  height: 12px;

  @include respondTo(l) {
    width: 20px;
    height: 20px;
  }
}

.description {
  grid-area: description;
  display: none;

  @include respondTo(l) {
    display: flex;
  }
}

.action {
  grid-area: action;
  display: flex;
  margin-top: auto;
  padding: 20px 12px;
  flex-direction: column-reverse;
  background-color: transparent;

  @include respondTo(l) {
    padding: 16px 20px;
    flex-direction: row;
    align-items: center;
    background-color: var(--secondary-800);
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
  }
}

.cost {
  font-size: 12px;
  line-height: 130%;
  color: var(--typography-200);
  text-align: center;
  width: 100%;
  margin-right: 0;

  @include respondTo(l) {
    width: 88px;
    margin-right: 10px;
  }
}

.buttonWrapper {
  margin-top: 0;
  flex-grow: 1;
  position: relative;
  z-index: 2;
  margin-bottom: 8px;

  @include respondTo(l) {
   margin-bottom: 0;
  }
}

.button {
  color: var(--typography-100);
  width: 100%;
  font-weight: 600;
  height: 40px;
  border-radius: 8px;

  @include respondTo(l) {
    border-radius: 30px;
  }
}

