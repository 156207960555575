@import "src/core/common/assets/styles/mixins";

.content {
  @include pageContainerMaxWidth(1155px);
  @include sectionSidePaddings();

  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: var(--typography-100);
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 12px;

  @include respondTo(l) {
    font-size: 40px;
    line-height: 47px;
    margin-bottom: 16px;
  }
}

.rating {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: var(--typography-100);
  display: flex;
  align-items: center;
  margin-bottom: 32px;

  @include respondTo(l) {
    margin-bottom: 40px;
  }
}

.stars {
  margin-left: 20px;
}

.reviewList {
  max-width: 400px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: -16px;

  @include respondTo(l) {
    max-width: none;
    margin-right: -32px;
  }
}

.reviewListItem {
  flex: 0 0 auto;
  width: 100%;
  max-width: 100%;
  padding-bottom: 16px;

  @include respondTo(l) {
    width: calc(100% / 3);
    padding-right: 32px;
  }
}

.reviewAvatar {
  width: 28px;
  height: 28px;
  color: var(--typography-100);
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  background: var(--gradient-tranquil);
}

.review {
  background-color: var(--secondary-600);
  height: auto;
  padding: 20px 16px;
  margin-bottom: 0;


  @include respondTo(l) {
    height: 100%;
  }
}

@include whenThemeIs(light) {
  .reviewAvatar {
    color: var(--typography-900);
  }
}
