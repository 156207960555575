@import "vars.module";
@import "themes";

@mixin respondTo($media) {
  @if $media == s {
    @media only screen and (max-width: $maximalMobileWidth) { @content; }
  } @else if $media == l {
    @media screen and (min-width: $minimalDesktopWidth) { @content; }
  } 
}

@mixin sectionSidePaddings() {
  padding-right: $sectionSidePadding;
  padding-left: $sectionSidePadding;

  @include respondTo(l) {
    padding-right: $sectionDesktopPadding;
    padding-left: $sectionDesktopPadding;
  }
}

@mixin pageContainerMaxWidth($width: $maximalContainerWidth) {
  margin: 0 auto;
  max-width: $width;
}

@mixin title($size: 24px, $sizeL: 40px) {
  font-family: "Open Sans", sans-serif;
  font-size: $size;
  text-align: center;
  line-height: 32px;
  font-weight: 600;

  @include respondTo(l) {
    font-size: $sizeL;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    line-height: 120%;
  }
}

@mixin transition($property: all, $duration: 100ms, $easing_function: ease-in-out, $delay: 0ms) {
  transition: $property $duration $delay $easing_function;
}

@mixin scale($factor: 1) {
  transform: scale($factor);
}

@mixin gradientText($background: var(--gradient-pink-base)) {
  background: $background;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}


@mixin forceTheme($theme) {
  @if $theme == d {
    @include darkThemeVariables();
  } @else if $theme == l {
    @include lightThemeVariables();
  }
}

@mixin gradientBorder(
  $primaryBackground: var(--gradient-pink-base),
  $secondaryBackground: var(--typography-900)
) {
  background: linear-gradient($secondaryBackground, $secondaryBackground) padding-box, $primaryBackground border-box;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border: 1px solid transparent;
}

@mixin whenThemeIs($theme) {
  @if $theme == dark {
    body[data-theme='dark'] { @content; }
  } @else if $theme == light {
    body[data-theme='light'] { @content; }
  }
}

@mixin clampBlockLines($lineHeightMobile, $lineHeightDesktop, $mobileLines, $desktopLines: $mobileLines) {
  display: -webkit-box;
  -webkit-line-clamp: $mobileLines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: $lineHeightMobile;
  height: calc($lineHeightMobile * $mobileLines);

  @include respondTo(l) {
    line-height: $lineHeightDesktop;
    height: calc($lineHeightDesktop * $desktopLines);
    -webkit-line-clamp: $desktopLines;
  }
}

@mixin clampInlineLines($mobileLines, $desktopLines: $mobileLines) {
  display: -webkit-box;
  -webkit-line-clamp: $mobileLines;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @include respondTo(l) {
    -webkit-line-clamp: $desktopLines;
  }
}

@mixin contentManagerTextReset {
  * {
    &:first-child {
      margin-top: 0 !important;
    }

    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  p, ol, ul  {
    color: var(--typography-100);
    font-size: 14px;
    margin-bottom: 32px;
    line-height: 150%;
    text-align: left;

    @include respondTo(l) {
      margin-bottom: 40px;
      font-size: 18px;
    }
  }

  ol, ul {
    padding-left: 15px;

    @include respondTo(l) {
      padding-left: 25px;
    }
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  h1 {
    color: var(--typography-100);
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 16px;
    text-align: left;

    @include respondTo(l) {
      text-align: center;
      font-size: 40px;
      line-height: 60px;
      margin-bottom: 24px;
    }
  }

  h2, h3, h4, h5 {
    color: var(--typography-100);
    font-weight: 700;
  }

  h2 {
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 12px;
    text-align: left;

    @include respondTo(l) {
      text-align: center;
      font-size: 40px;
      line-height: 54px;
      margin-bottom: 16px;
    }
  }

  h3 {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 12px;

    @include respondTo(l) {
      font-size: 32px;
      line-height: 44px;
      margin-bottom: 16px;
    }
  }

  h4 {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 8px;

    @include respondTo(l) {
      font-size: 20px;
      line-height: 27px;
    }
  }

  h5 {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 4px;

    @include respondTo(l) {
      font-size: 18px;
      line-height: 25px;
    }
  }

  i, em {
    font-style: italic;
  }

  strong, b {
    font-weight: bold;
  }

  img {
    display: inline-block;
    max-width: 100%;
  }

  a {
    text-decoration: underline;
  }

  @content;
}

@mixin contentManagerSectionHeading {
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: var(--typography-100);
  margin-bottom: 16px;

  @include textWrapBalance();

  @include respondTo(l) {
    font-size: 40px;
    line-height: 47px;
    margin-bottom: 24px;
  }

  @content;
}

@mixin truncateText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


@mixin elementsBackgrounds {
  .earth {
    background-color: var(--success-100);
  }

  .fire  {
    background-color: var(--tertiary-100);
  }

  .air {
    background-color: var(--primary-100);
  }

  .water {
    background-color: var(--chart-100);
  }
  
  @include whenThemeIs(light) {
    .fire  {
      background-color: var(--tertiary-900);
    }

    // TODO: Move this to variables or choose another variable
    .air {
      background-color: #E8E8FC;
    }

    .water {
      background-color: var(--chart-900);
    }
  }
}

@mixin positionFutureImage($maxWidth: 100%, $maxWidthDesktop: null) {
  height: auto;
  max-width: $maxWidth;
  width: 100%;
  object-position: bottom center;
  object-fit: contain;

  @if $maxWidthDesktop {
    @include respondTo(l) {
      max-width: $maxWidthDesktop;
    }
  }
}

@mixin hideOnMobile($display: block) {
  display: none;

  @include respondTo(l) {
    display: $display;
  }
}

@mixin hideOnDesktop($display: block) {
  display: $display;

  @include respondTo(l) {
    display: none;
  }
}

@mixin fillContainer($position: absolute) {
  position: $position;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

@mixin whenAspectRatioNotSupported {
  @supports not (aspect-ratio: 1) {
    @content;
  }
}

@mixin aspectRatio($width, $height) {
  aspect-ratio: $width / $height;

  @include whenAspectRatioNotSupported() {
    &::before {
      content: '';
      display: inline-block;
      vertical-align: top;
      padding-top: 100% * $height / $width;
    }
  }
}

@mixin textWrapBalance {
  @supports (text-wrap: balance) {
    text-wrap: balance;
  }

  @supports not (text-wrap: balance) {
    text-align: center;
  }

  @content;
}

@mixin whenHasHover {
  @media (hover: hover) {
    @content;
  }
}

@mixin onHover {
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @content;
    }
  }
}

@mixin defaultSectionMarginBottom {
  margin-bottom: 60px;

  @include respondTo(l) {
    margin-bottom: 80px;
  }
}

@mixin appendBlinkingCursor($delay: 0ms, $duration: 0ms) {
  &::after {
    content: "|";
    font-weight: 700;
    font-size: 1em;

    /* The animation will consist of three phases:
       1. Delay before the start (transparency)
       2. Cursor blinking
       3. Disappearance
    */
    animation: visibilityControl $duration steps(2, end) 1 normal forwards,
               animationBlink 0.5s step-start infinite;
    animation-delay: $delay;
    opacity: 0;
  }
}

@keyframes animationBlink {
  50% {
    opacity: 0;
  }
}

@keyframes visibilityControl {
  0% {
    opacity: 0;
  }

  1% {
    opacity: 1;
  }

  99% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    visibility: hidden;
    display: none;
  }
}

@mixin addTopScrollMargin($mobile: $scrollMarginTopMobile, $desktop: $scrollMarginTopDesktop) {
  scroll-margin-top: $mobile;

  @include respondTo(l) {
    scroll-margin-top: $desktop;
  }
}

@mixin addTopScrollMarginToHeadings($mobile: $scrollMarginTopMobile, $desktop: $scrollMarginTopDesktop) {
  h1, h2, h3, h4, h5, h6 {
    @include addTopScrollMargin($mobile, $desktop);
  }
}