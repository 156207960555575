.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  font-size: 24px;
  line-height: 24px;
  outline: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background: transparent;
  flex: 0 0 auto;
  overflow: visible;
  border-radius: 50%;
  padding: 0;
  border: none;

  svg {
    fill: currentColor;
    transition: fill 0.3s ease-in-out, opacity 0.3s ease-in-out;
    flex-shrink: 0;
    display: inline-block;
    user-select: none;
    width: 1em;
    height: 1em;
  }
}

.label {
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
}
