@import "src/core/common/assets/styles/mixins";

.root {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  max-width: 732px;
  border-radius: 12px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @include aspectRatio(16, 9);
}

.playButton {
  display: block;
  width: 100%;
  height: 100%;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 48"><path d="M66.52 7.74c-.78-2.93-2.49-5.41-5.42-6.19C55.79.13 34 0 34 0S12.21.13 6.9 1.55c-2.93.78-4.63 3.26-5.42 6.19C.06 13.05 0 24 0 24s.06 10.95 1.48 16.26c.78 2.93 2.49 5.41 5.42 6.19C12.21 47.87 34 48 34 48s21.79-.13 27.1-1.55c2.93-.78 4.64-3.26 5.42-6.19C67.94 34.95 68 24 68 24s-.06-10.95-1.48-16.26z" fill="red"/><path d="M45 24 27 14v20" fill="white"/></svg>') no-repeat center / 68px 48px;
  position: absolute;
}

.playerActive {
  .playButton {
    display: none;
  }
}

.iframe {
  border-radius: 16px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}