@import "src/core/common/assets/styles/mixins";

.root {
  background: var(--white-with-opacity-5);
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 20px;

  @include respondTo(l) {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.content {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 32px 12px;

  @include respondTo(l) {
    max-width: 1320px;
    padding: 52px 80px 37px;
  }
}

.quoteWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  @include respondTo(l) {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 44px;
  }
}

.quotationMarks {
  width: 32px;
  height: 32px;
  position: relative;

  @include respondTo(l) {
    width: 80px;
    height: 80px;
  }
}

.openingQuotationMarks {
  margin-bottom: 15px;

  @include respondTo(l) {
    margin-bottom: 0;
  }
}

.closingQuotationMarks {
  transform: scale(-1);

  @include hideOnMobile();
}

.quoteText {
  font-size: 14px;
  font-style: italic;
  line-height: 150%;
  text-align: left;
  color: var(--typography-100);

  @include respondTo(l) {
    max-width: 700px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    text-align: center;
  }
}

.numbersList {
  display: flex;
  align-items: stretch;
  margin-left: -4px;
  margin-right: -4px;
  flex-wrap: wrap;

  @include respondTo(l) {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.numbersItem {
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 8px;
  width: 50%;

  @include respondTo(l) {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    width: 25%;
  }
}

.numbersItemInner {
  background-color: var(--primary-page-background);
  padding: 8px 10px;
  border-radius: 12px;
  text-align: left;
  height: 100%;

  @include respondTo(l) {
    padding: 8px 22px;
    text-align: center;
  }
}

.numbersAmount {
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  color: var(--primary-300);

  @include respondTo(l) {
    font-size: 36px;
    line-height: 49px;
  }
}

.numbersUnits {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: var(--primary-300);

  @include respondTo(l) {
    font-size: 24px;
    line-height: 33px;
  }
}

.numbersSubject {
  color: var(--typography-100);
  font-size: 14px;
  line-height: 21px;

  @include respondTo(l) {
    font-size: 24px;
    line-height: 33px;
  }
}

