@import "src/core/common/assets/styles/mixins";

.root {
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 20px;
  margin-bottom: 20px;

  @include respondTo(l) {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 80px;
  }
}

.hasBackground {
  background: var(--white-with-opacity-5);
}

.content {
  @include pageContainerMaxWidth();
  @include sectionSidePaddings();

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 32px;

  @include respondTo(l) {
    padding: 60px 100px;
  }
}

.title {
  text-align: center;
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  color: var(--typography-100);
  margin-bottom: 16px;

  @include respondTo(l) {
    font-size: 40px;
    line-height: 47px;
    margin-bottom: 24px;
  }
}

.text {
  @include pageContainerMaxWidth($contentManagerSectionTextWidth);
  @include contentManagerTextReset();

  margin-bottom: 15px;

  @include respondTo(l) {
    margin-bottom: 26px;
  }
}

.buttonWrapper {
  display: flex;
  width: 100%;
  margin: 0;

  @include respondTo(l) {
    justify-content: center;
  }
}

.button {
  background: transparent;
  padding: 0;
  width: auto;
  height: auto;
  color: var(--primary);
  font-size: 20px;
  line-height: 27px;
}
